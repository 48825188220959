<template>
  <section class="col-xl-8 col-lg-10">
    <v-card flat>
      <div class="d-flex mx-5 mb-5">
        <v-btn small text color="success" outlined @click="recover"
          >Recover</v-btn
        >
        <v-btn small text color="danger" @click="permanently_delete">
          <v-icon>mdi-delete-outline</v-icon>
          Permanently</v-btn
        >
      </div>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="students"
        :items-per-page="10"
        show-select
        checkbox-color="success"
      >
        <template v-slot:[`item.email`]="{ item }">
          <div class="caption">{{ `${item.email}` }}</div>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <div class="caption">{{ `${item.firstname} ${item.lastname}` }}</div>
        </template>
        <template v-slot:[`item.date`]="{ item }">
          <div class="caption">{{ $helpers.dateDiff(item.deleted_at) }}</div>
        </template>
      </v-data-table>
    </v-card>

    <snackbar
      :snack="snack"
      :text="text"
      timeout="3000"
      @close="snack = false"
    />
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["students"],
  data: () => ({
    headers: [
      {
        text: "Email",
        align: "start",
        value: "email",
      },
      { text: "Name", value: "name" },
      { text: "Deleted", value: "date" },
    ],
    selected: [],
    snack: false,
    text: "",
  }),
  methods: {
    ...mapActions("admin", [
      "recoverStudents",
      "getTrashedStudentsAction",
      "deleteStudentsPermanently",
    ]),

    recover() {
      if (this.selected.length == 0) return;

      this.recoverStudents({
        ids: this.collected(),
      }).then(() => {
        if (this.$errors("admin")) {
          console.log("errors");
          return;
        }
        this.getTrashedStudentsAction();
        this.snack = true;
        this.text = "Students Restored!";
      });
    },

    permanently_delete() {
      if (this.selected.length == 0) return;

      if (
        confirm(
          "Delete this selected accounts?\nRemember this action is not revertable"
        )
      ) {
        this.deleteStudentsPermanently({
          ids: this.collected(),
        }).then(() => {
          if (this.$errors("admin")) {
            console.log("errors");
            return;
          }
          this.getTrashedStudentsAction();
          this.snack = true;
          this.text = "Students Deleted!";
        });
      }
    },

    collected() {
      let ids = [];
      this.selected.forEach((el) => {
        ids.push(el.id);
      });
      return ids;
    },
  },
};
</script>