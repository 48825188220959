<template>
  <section id="trashbin" class="ma-10">
    <div class="display-1 font-weight-bold mb-10">Recycle Bin</div>

    <v-tabs v-model="tab" background-color="transparent">
      <v-tabs-slider color="transparent"></v-tabs-slider>
      <v-tab
        class="body-2"
        style="text-transform: capitalize"
        v-for="item in items"
        :key="item"
      >
        {{ item }}
      </v-tab>
    </v-tabs>
    <v-divider />
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in items" :key="item">
        <Student
          :students="students"
          v-if="item === 'Student' && students.length > 0"
        />

        <div v-else class="ma-5 d-flex align-center">
          <v-icon>mdi-delete-empty</v-icon>
          <div class="">No data</div>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Student from "../components/account/TrashStudent.vue";

export default {
  components: {
    Student,
  },
  data: () => ({
    tab: null,
    items: ["Student", "Teacher"],
  }),
  computed: {
    ...mapState("admin", {
      students: (state) => state.students,
    }),
  },
  created() {
    /**
     * To eliminate error in hook mapAction
     */
    this.$store.dispatch("admin/getTrashedStudentsAction");
  },
  methods: {
    ...mapActions("admin", []),
  },
};
</script>